import React, { useEffect, useState, useCallback } from 'react';
import isMobile from 'is-mobile';
import MinimizeIcon from '../Icons/MinimizeIcon';
import MaximizeIcon from '../Icons/MaximizeIcon';
import { isWindowFullSized } from '../../utils/fullScreen';
import screenfull from 'screenfull';
import cx from 'clsx';

import './styles.scss';

function openFullscreen() {
  if (screenfull.isEnabled) {
    screenfull.request();
  }
}

function exitFullscreen() {
  if (screenfull.isEnabled) {
    screenfull.exit();
  }
}

interface Props {
  isIdle: boolean;
}

const FullScreenToggleButton = ({ isIdle }: Props) => {
  const initialIsFullScreen = isWindowFullSized();
  const [isFullScreen, setIsFullScreen] = useState(initialIsFullScreen);

  const toggleFullScreen = () => {
    const windowIsFulledSized = isWindowFullSized();
    if (windowIsFulledSized) {
      exitFullscreen();
      setIsFullScreen(false);
    } else {
      openFullscreen();
      setIsFullScreen(true);
    }
  };

  const fullScreenHandler = useCallback(() => {
    if (isWindowFullSized() || isFullScreen) {
      setIsFullScreen(true);
    }
  }, [isFullScreen, setIsFullScreen]);

  const resizeHandler = useCallback(() => {
    setTimeout(() => {
      setIsFullScreen(isWindowFullSized());
    }, 30);
  }, []);

  useEffect(() => {
    const callback = () => {
      setIsFullScreen(isWindowFullSized());
    };
    document.addEventListener('fullscreenchange', callback);
    window.addEventListener('resize', resizeHandler);

    return () => {
      document.removeEventListener('fullscreenchange', fullScreenHandler);
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  if (isMobile()) {
    return null;
  }

  return (
    <div className={cx('toggle-fullscreen', { '-hidden': isIdle })} onClick={toggleFullScreen}>
      {isFullScreen ? <MinimizeIcon className="icon" /> : <MaximizeIcon className="icon" />}
    </div>
  );
};

export default FullScreenToggleButton;
