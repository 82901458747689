import React from 'react';
import cx from 'clsx';
import { getTransportModeClass } from '../../utils/lineColors';

import './styles.scss';

interface Props {
  lineNumber?: string;
  transportMode: Mon.TransportMode;
  className?: string;
}

const LineNumber = ({ lineNumber = '', transportMode, className }: Props) => {
  const transportModeLineClass = getTransportModeClass(lineNumber, transportMode);

  return (
    <div className="line-wrapper">
      <div className={cx('line-number', transportModeLineClass, className)}>
        <div className="line-text">{lineNumber}</div>
      </div>
    </div>
  );
};

export default LineNumber;
