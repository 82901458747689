import React from 'react';
import { Switch } from '@ruter-ds-next/switch';
import { Entur } from '@ruter-as/dpi-frontend';
import { transportModeLabels } from '../../shared/utils/transportModes';

import './styles.scss';

const rdsSkinColors = {
  rail: 'train',
  bus: 'bus',
  metro: 'metro',
  tram: 'tram',
  water: 'boat',
  coach: 'bus',
};

interface Props {
  transportModes: Entur.TransportMode[];
  handleToggleTransportMode: Mon.AnyFunction;
  toggledTransportModes: Entur.TransportMode[];
}

const TransportModeFilters = ({
  transportModes = [],
  handleToggleTransportMode,
  toggledTransportModes = [],
}: Props) => {
  if (!transportModes.length) {
    return null;
  }

  return (
    <div className="transportModesFilter">
      <span className="transportModesFilterLabel">Hvilke transportmidler vil du se?</span>
      <div className="transportModes">
        {transportModes.map((transportMode) => {
          const checked = !toggledTransportModes.includes(transportMode);
          return (
            <div className="flex gap-2">
              <Switch
                key={transportMode}
                label={transportModeLabels[transportMode]}
                checked={checked}
                onChange={(e) => {
                  handleToggleTransportMode(transportMode, e.target.checked);
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TransportModeFilters;
