import React from 'react';
import { ExclamationTriangle } from '@ruter-ds-next/icons';
import cx from 'clsx';
import { range } from '../../utils/numbers';

import './styles.scss';

interface Props {
  situation: Mon.SituationVisual;
  count: number;
  situationIndex: number;
  currentSituationSize: number;
}
export default function Situation({ situation, count, situationIndex, currentSituationSize }: Props) {
  const dotRange = range(count);

  const className = cx('situation', {
    '-situation-1': currentSituationSize === 1,
    '-situation-2': currentSituationSize === 2,
    '-situation-3': currentSituationSize === 3,
  });

  return (
    <div className={className}>
      <ExclamationTriangle className="w-[3em] h-[3em] fill-[#1B1F4A]" />
      <div className="situation-text">{situation.value}</div>
      <div className="dots">
        {dotRange.map((item, index) => (
          <div className={cx('dot', { '-active-dot': situationIndex === index })} key={`dot-${item}`}></div>
        ))}
      </div>
    </div>
  );
}
