import differenceInSeconds from 'date-fns/differenceInSeconds'

export const getSecondsToDeparture = (isoString?: string): number | null => {
  if (!isoString) {
    return null;
  }
  try {
    const date = new Date(isoString);
    const now = new Date();
    return differenceInSeconds(date, now);
  } catch (error) {
    return null;
  }
};

export const getMilisecondsOffset = () => {
  return (60 - new Date().getSeconds()) * 1000;
};

export const getClock = () => {
  const now = new Date();
  return [now.getHours(), now.getMinutes()].map((d) => {
    return (d < 10 ? '0' + d : d) + '';
  });
};
