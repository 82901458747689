import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import cx from 'clsx';
import { Entur } from '@ruter-as/dpi-frontend';
import { Refresh } from '@ruter-ds-next/icons';
import { Button } from '@ruter-ds-next/button';
import Header from '../../shared/components/Header';
import ChooseLayout from '../ChooseLayout';
import { updateURLState, getPathName, loadURLState, DEFAULT_SETTINGS } from '../../shared/utils/URL';
import { setupBoards } from '../../shared/utils/boards';
import {
  getFilteredBoardsConfig,
  removeStopPlaceId,
  updateBoardTitle,
  updateEnabledBoard,
  updateNumberOfDepartures,
  updateStopPlaceIds,
  updateToggleAllLines,
  updateToggleEnableLine,
  updateWhitelistedTransportModes,
  updateDelayedStartTime,
  updateHideTrackColumn,
} from '../../shared/utils/boardSettings';
import BackButton from '../BackButton';
import { getStopPlacesByCoords } from '../../shared/data/graphql';
import BoardConfig from '../BoardConfig';
import useTitle from '../../utils/use-title';
import { LogoColor } from '../../shared/components/Icons/Logo';

import './styles.scss';

const Settings = () => {
  useTitle('- Innstillinger');
  const [settings, setSettings] = useState<Mon.Settings>(DEFAULT_SETTINGS);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const navigate = useNavigate();
  const { coords } = useParams();

  const handleUpdate = (e) => {
    e.preventDefault();

    setIsDirty(false);
    const filteredBoards = getFilteredBoardsConfig(settings.boards);
    const moreThanOneBoard = filteredBoards.length > 1;
    const useColumnLayout = moreThanOneBoard ? settings.useColumnLayout : false;

    updateURLState({
      ...settings,
      useColumnLayout,
    });
    const pathName = getPathName('departures');
    navigate(pathName);
    e.preventDefault();
  };

  const handleUpdateNumberOfDepartures = (numberOfDepartures: number, boardIndex: number) => {
    setIsDirty(true);
    setSettings({
      ...settings,
      boards: updateNumberOfDepartures(settings.boards, boardIndex, numberOfDepartures),
    });
  };

  const handleUpdateDelayedStartTime = (minutes: number, boardIndex: number) => {
    setIsDirty(true);
    setSettings({
      ...settings,
      boards: updateDelayedStartTime(settings.boards, boardIndex, minutes),
    });
  };

  const handleToggleEnableBoard = (value: boolean, boardIndex: number) => {
    setIsDirty(true);
    setSettings({
      ...settings,
      boards: updateEnabledBoard(settings.boards, boardIndex, value),
    });
  };

  const handleHideAnimation = (value: boolean) => {
    setIsDirty(true);
    setSettings({
      ...settings,
      hideAnimations: value,
    });
  };

  const handleUpdateHideTrack = (value: boolean, boardIndex: number) => {
    setIsDirty(true);
    setSettings({
      ...settings,
      boards: updateHideTrackColumn(settings.boards, boardIndex, value),
    });
  };

  const handleToggleEnableLine = (checked: boolean, id: string, boardIndex: number, stopPlaceId: string) => {
    setIsDirty(true);
    setSettings({
      ...settings,
      boards: updateToggleEnableLine(settings.boards, boardIndex, stopPlaceId, id, checked),
    });
  };

  const handleAddStopPlace = (boardIndex: number, coords: Mon.Coordinates | null) => {
    if (coords) {
      const { latitude, longitude } = coords;
      getStopPlacesByCoords(latitude, longitude).then((stopPlaces) => {
        const stopPlaceIds = stopPlaces.map(({ id }) => id);
        setIsDirty(true);
        setSettings({
          ...settings,
          boards: updateStopPlaceIds(settings.boards, boardIndex, stopPlaceIds),
        });
      });
    }
  };

  const handleRemoveStopPlace = (stopPlaceId: string, bordIndex: number) => {
    setIsDirty(true);
    setSettings({
      ...settings,
      boards: removeStopPlaceId(settings.boards, bordIndex, stopPlaceId),
    });
  };

  const onToggleTransportMode = (index: number, transportMode: Entur.TransportMode, enabled: boolean) => {
    setIsDirty(true);
    setSettings({
      ...settings,
      boards: updateWhitelistedTransportModes(settings.boards, index, transportMode, enabled),
    });
  };

  const handleToggleAllLines = (event: any, boardIndex: number, lines: Entur.LocationLine[], stopPlaceId: string) => {
    setIsDirty(true);
    const checked = event.target.checked;
    setSettings({
      ...settings,
      boards: updateToggleAllLines(settings.boards, boardIndex, stopPlaceId, checked, lines),
    });
  };

  const handleUpdateBoardTitle = (title: string, boardIndex: number) => {
    setIsDirty(true);
    setSettings({
      ...settings,
      boards: updateBoardTitle(settings.boards, boardIndex, title),
    });
  };

  useEffect(() => {
    const settingsFromURL = loadURLState();
    const { boards } = settingsFromURL;

    if ((!boards || !boards.length) && coords) {
      const [latitude, longitude] = coords.split('-');
      setupBoards(latitude, longitude, settingsFromURL, (newSettings: Mon.Settings) => {
        setSettings(newSettings);
        setIsLoading(false);
      });
    } else {
      setSettings(settingsFromURL);
      setIsLoading(false);
    }
  }, [coords]);

  if (isLoading) {
    return (
      <div className="settingsPage">
        <Header logoColor={LogoColor.red} showClock={false} />
        <BackButton />
        <div className="loadingContent">
          <Refresh className="w-8 h-8" />
        </div>
      </div>
    );
  }

  return (
    <div className="settingsPage">
      <Header logoColor={LogoColor.red} showClock={false} navigateToSettings={false} />
      <div className="topContent">
        <BackButton />
        <div className="topButtons">
          <Button
            type="submit"
            className={cx('button', { '-disabled': !isDirty })}
            skin="SECONDARY"
            onClick={(e) => {
              handleUpdate(e);
            }}
          >
            Oppdater
          </Button>
        </div>
      </div>
      <div className="cards">
        {settings.boards.map((board, index) => {
          return (
            <BoardConfig
              key={index}
              index={index}
              config={board}
              handleToggleEnableBoard={handleToggleEnableBoard}
              handleUpdateHideTrack={(value) => {
                handleUpdateHideTrack(value, index);
              }}
              handleUpdateNumberOfDepartures={({ value }) => {
                handleUpdateNumberOfDepartures(value, index);
              }}
              handleUpdateDelayedStartTime={({ value }) => {
                handleUpdateDelayedStartTime(value, index);
              }}
              handleUpdateTitle={(value) => {
                handleUpdateBoardTitle(value, index);
              }}
              toggleEnableLine={handleToggleEnableLine}
              toggleShowAllLines={handleToggleAllLines}
              handleAddStopPlaces={(coords) => {
                handleAddStopPlace(index, coords);
              }}
              handleToggleTransportMode={(transportMode, enabled) => {
                onToggleTransportMode(index, transportMode, enabled);
              }}
              removeStopPlace={(stopPlaceId) => {
                handleRemoveStopPlace(stopPlaceId, index);
              }}
            />
          );
        })}
        <ChooseLayout
          useColumnLayout={settings.useColumnLayout}
          settings={settings}
          handleHideAnimation={handleHideAnimation}
          handleChangeColumnLayout={(useColumnLayout: boolean) => {
            setIsDirty(true);
            setSettings({
              ...settings,
              useColumnLayout,
            });
          }}
        />
      </div>
    </div>
  );
};

export default Settings;
