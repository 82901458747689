import React, { useEffect, useState, useRef } from 'react';
import { Checkbox } from '@ruter-ds-next/checkbox';
import { Switch } from '@ruter-ds-next/switch';
import { Button } from '@ruter-ds-next/button';
import { Input } from '@ruter-ds-next/input';
import { Entur } from '@ruter-as/dpi-frontend';

import { AccordionHeading, AccordionContent, Accordion } from '../../shared/components/Accordion';
import { getLinesSuggestions } from '../../shared/data/graphql';
import Track from '../../shared/components/Track';
import {
  createHiddenLineString,
  getStopPlacesTransportModes,
  getStopPlaceTransportModes,
  isLineHidden,
} from '../../shared/utils/lines';
import StopPlaceSearch from '../StopPlaceSearch';
import TransportModeFilters from '../TransportModeFilters';
import TransportIconsPanel from '../TransportIconsPanel';

import './styles.scss';

interface Props {
  config: Mon.BoardConfig;
  index: number;
  handleUpdateNumberOfDepartures: Mon.AnyFunction;
  handleUpdateDelayedStartTime: Mon.AnyFunction;
  handleUpdateTitle: Mon.AnyFunction;
  toggleEnableLine: Mon.AnyFunction;
  toggleShowAllLines: Mon.AnyFunction;
  handleAddStopPlaces: Mon.AnyFunction;
  handleToggleTransportMode: Mon.AnyFunction;
  removeStopPlace: Mon.AnyFunction;
  handleToggleEnableBoard: Mon.AnyFunction;
  handleUpdateHideTrack: Mon.AnyFunction;
}

const BoardConfig: React.FC<Props> = ({
  config,
  handleUpdateNumberOfDepartures,
  handleUpdateDelayedStartTime,
  index,
  handleUpdateTitle,
  toggleEnableLine,
  toggleShowAllLines,
  handleAddStopPlaces,
  handleToggleTransportMode,
  removeStopPlace,
  handleToggleEnableBoard,
  handleUpdateHideTrack,
}: Props) => {
  const [stopPlacesWithLines, setStopPlacesWithLines] = useState<Entur.LocationStopPlace[]>([]);
  const [transportModes, setTransportModes] = useState<Entur.TransportMode[]>([]);
  const hiddenLines = config.hiddenLines || [];

  useEffect(() => {
    if (config && config.stopPlaceIds.length) {
      getLinesSuggestions(config.stopPlaceIds).then((result) => {
        const transportModes = getStopPlacesTransportModes(result);
        setTransportModes(transportModes);
        setStopPlacesWithLines(result);
      });
    } else {
      setTransportModes([]);
      setStopPlacesWithLines([]);
    }
  }, [config.stopPlaceIds]);

  const boardEnabled = typeof config.enabled === 'boolean' ? config.enabled : true;
  return (
    <div className="board-config card">
      <div className="card-header">
        <div>{`Tavle ${index + 1}`}</div>
        <Switch
          label="Vis"
          checked={boardEnabled}
          onChange={(e) => handleToggleEnableBoard(e.target.checked, index)}
        />
      </div>
      <div className={'card-content ' + (!boardEnabled ? '-inactive' : '')}>
        <div className="board-title-wrapper">
          <Input
            label="Tittel"
            onChange={(e) => {
              handleUpdateTitle(e.target.value, index);
            }}
            value={config.title}
          />
        </div>
        <StopPlaceSearch handleSelectItem={handleAddStopPlaces} label="Legg til stoppested" />
        <TransportModeFilters
          transportModes={transportModes}
          handleToggleTransportMode={handleToggleTransportMode}
          toggledTransportModes={config.disabledTransportModes}
        />
        <span className="chooseDeparturesLabel">
          Antall avganger du vil vise i tavlen: <span className="bold">{config.numberOfDepartures}</span>
        </span>
        <div>
          <input
            min={0}
            max={20}
            name="numberOfDepartures"
            type="range"
            value={config.numberOfDepartures}
            step={1}
            onChange={(e) => handleUpdateNumberOfDepartures({ value: Number(e.target.value) })}
          />
        </div>
        <span className="chooseDeparturesLabel">
          Vis avganger om: <span className="bold">{config.delayedStartTime}</span>{' '}
          {config.delayedStartTime === 1 ? 'minutt' : 'minutter'}
        </span>
        <div className="elementMargin">
          <input
            type="range"
            min={0}
            max={20}
            value={config.delayedStartTime}
            step={1}
            onChange={(e) => handleUpdateDelayedStartTime({ value: Number(e.target.value) })}
            className="range-input"
          />
        </div>
        <div className="elementMargin flex gap-2">
          <Switch
            label="Skjul plattform for denne tavlen"
            checked={config.hideTrackColumn}
            onChange={(e) => handleUpdateHideTrack(e.target.checked)}
          />
        </div>
        <div className="lineSuggestions">
          <div className="stopPlacesLabel">Stoppesteder</div>
          <div>
            {stopPlacesWithLines.map((stopPlace) => {
              const { id, name, lines } = stopPlace;
              const stopPlaceTransportModes = getStopPlaceTransportModes(stopPlace);
              return (
                <Accordion key={id} className="p-4 rounded-md shadow flex flex-col gap-4">
                  <AccordionHeading className="">
                    <TransportIconsPanel transportModes={stopPlaceTransportModes} />
                    <span>{name}</span>
                  </AccordionHeading>
                  <AccordionContent>
                    <>
                      <div key={'choose-all'} className="lineSuggestion">
                        <label>
                          <Checkbox
                            checked={!(hiddenLines[id] || []).length}
                            className="checkAllLines"
                            value={`check-all-lines`}
                            onChange={(e) => {
                              toggleShowAllLines(e, index, lines, id);
                            }}
                          />
                          Velg alle
                        </label>
                        <Button
                          skin="DESTRUCTIVE"
                          onClick={() => removeStopPlace(id)}
                          size="SMALL"
                          className="whitespace-nowrap"
                        >
                          Fjern stopp
                        </Button>
                      </div>
                      {lines.map((line, i) => {
                        const checked = !isLineHidden(hiddenLines, id, line.quay.id, line.publicCode, line.frontText);
                        const disabled = (config.disabledTransportModes || []).includes(line.transportMode);
                        return (
                          <div key={i} className="lineSuggestion">
                            <label>
                              <Checkbox
                                disabled={disabled}
                                checked={checked}
                                value={`line-check-${line.id}`}
                                onChange={(e) => {
                                  toggleEnableLine(
                                    e.target.checked,
                                    createHiddenLineString(line.quay.id, line.publicCode, line.frontText),
                                    index,
                                    id
                                  );
                                }}
                              />
                              {`${line.publicCode} ${line.frontText}`}
                            </label>
                            <div className="track">
                              <Track quayPublicCode={line.quay.publicCode} transportMode={line.transportMode} small />
                            </div>
                          </div>
                        );
                      })}
                    </>
                  </AccordionContent>
                </Accordion>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoardConfig;
