import React, { useState, useEffect } from 'react';
import cx from 'clsx';
import { getFilteredBoardsConfig } from '../../utils/boardSettings';
import { getDepartures } from '../../data/graphql';
import Board from '../Board';
import NoDepartures from '../../../components/NoDepartures';
import UnableToFetch from '../../../components/UnableToFetch';

import './styles.scss';
import { mapSituations } from '../../data/mapUtils';
import { useSituations } from '../../hooks/useSituations';
import Situation from '../Situation';

const REFRESH_INTERVAL = 20 * 1000;

interface Props {
  boardsConfig: Mon.BoardConfig[];
  useColumnLayout: boolean;
}

const Boards: React.FC<Props> = ({ boardsConfig = [], useColumnLayout }: Props) => {
  const [departures, setDepartures] = useState<Mon.Departure[][]>([]);
  const [fetchError, setFetchError] = useState(null);
  const { currentSituation, currentSituationSize, situationIndex, situations, setSituations } = useSituations();
  const filteredBoardsConfig = getFilteredBoardsConfig(boardsConfig);
  const actualUseColumnLayout = filteredBoardsConfig.length > 1 ? useColumnLayout : false;
  const boardsClasses = cx('boards', {
    useColumnLayout: actualUseColumnLayout,
  });

  const fetchDepartures = () => {
    if (filteredBoardsConfig.length) {
      getDepartures(filteredBoardsConfig)
        .then((departures: Mon.Departure[][]) => {
          setFetchError(null);
          setDepartures(departures);
          setSituations(mapSituations(departures));
        })
        .catch((err) => {
          console.error(err);
          setFetchError(err);
        });
    }
  };

  useEffect(() => {
    fetchDepartures();
    const intervalId = setInterval(() => {
      fetchDepartures();
    }, REFRESH_INTERVAL);

    return () => clearInterval(intervalId);
  }, [boardsConfig]);

  if (fetchError) {
    return <UnableToFetch />;
  }

  return (
    <div>
      <div className={boardsClasses}>
        {filteredBoardsConfig.map((boardConfig, index) => (
          <Board
            key={`board-${index}`}
            departures={departures[index]}
            config={boardConfig}
            useColumnLayout={actualUseColumnLayout}
            situation={currentSituation}
          />
        ))}
        {filteredBoardsConfig.length === 0 && <NoDepartures boardsConfig={boardsConfig} />}
      </div>
      {currentSituation && (
        <Situation
          situation={currentSituation}
          count={situations.length}
          situationIndex={situationIndex}
          currentSituationSize={currentSituationSize}
        />
      )}
    </div>
  );
};

export default Boards;
