import formatDate from 'date-fns/format';

const calculateSecondsIn = (minutes = 0, seconds = 0) => minutes * 60 + seconds;

export const getETAText = (etaDiffInSeconds: number | null, departureTime: string | Date, realtime: boolean) => {
  try {
    if (typeof etaDiffInSeconds !== 'number') {
      return '';
    }

    if (!realtime) {
      return formatDate(new Date(departureTime), "HH:mm");
    }

    if (0 > etaDiffInSeconds) {
      return 'nå';
    }

    if (etaDiffInSeconds >= 0 && etaDiffInSeconds <= 45) {
      return 'nå';
    }

    const showMinutesThreshold = calculateSecondsIn(14, 46);

    if (etaDiffInSeconds >= showMinutesThreshold) {
      return formatDate(new Date(departureTime), "HH:mm");
    }

    if (etaDiffInSeconds < showMinutesThreshold) {
      const minutes = Math.floor(etaDiffInSeconds / 60);
      const restSeconds = etaDiffInSeconds % 60;
      if (restSeconds > 45) {
        return `${minutes + 1} min`;
      } else {
        return `${minutes} min`;
      }
    }

    return `${etaDiffInSeconds} min`;
  } catch (error) {
    console.error('Unable to parse ETA test', error);
    return '';
  }
};
