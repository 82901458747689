import React from 'react';
import cx from 'clsx';

import './styles.scss';

interface Props {
  useColumnLayout: boolean;
  hideTrackColumn: boolean;
}

const DeparturesHeader = ({ useColumnLayout, hideTrackColumn }: Props) => {
  return (
    <div
      className={cx('departureHeaders', {
        rows: !useColumnLayout,
        hideTrackColumn,
      })}
    >
      <div></div>
      <div>
        Avgang
        <div className="en">Departure</div>
      </div>
      {!hideTrackColumn && (
        <div className="platform">
          Plf.
          <div className="en">Plf.</div>
        </div>
      )}
      <div> </div>
    </div>
  );
};

export default DeparturesHeader;
